.deposit-modal {
  max-width: 300px;
  .wallet-balance {
    background: #e5e5e5;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
