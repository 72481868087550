@import "_vars";
@import "react-toastify/dist/ReactToastify.css";

:root {
  --text-default: #{$text_default};
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text_default;
  font-size: 14px;
}

h1 {
  color: #000;
  font-size: 3em;
}

.main {
  padding: 32px 64px;
}

.mt {
  margin-top: $default_spacing;
}

.mb {
  margin-bottom: $default_spacing;
}

.mt-2x {
  margin-top: $default_spacing * 2;
}

.mb-2x {
  margin-bottom: $default_spacing * 2;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-muted {
  color: $text_muted;
}

a:hover {
  text-decoration: underline;
}

a {
  color: $text_default;
}

.no-scroll {
  overflow: hidden;
}

// react-toastify override
.Toastify__toast {
  @include base_shadow;
  border-radius: $radius;
  background-color: white;
  color: $text_default;
  line-height: 1.2rem;

  .Toastify__toast-body {
    padding-top: 32px !important;
  }

  &.Toastify__toast--success {
    padding-top: $default_spacing * 2 + 6px;
    padding-bottom: $default_spacing;

    &::before {
      background-image: url("assets/images/checkmark.svg");
      background-repeat: no-repeat;
      color: $green;
      content: "Success!";
      top: $default_spacing / 2;
      position: absolute;
      font-weight: bold;
      line-height: 1.8em;
      padding-left: 1.8em;
      margin-left: -2px; // To account for the icon's viewbox padding.
    }

    .Toastify__progress-bar {
      background-color: $green;
    }
  }

  &.Toastify__toast--error {
    &::before {
      background-image: url("assets/images/exclamation-circle-solid.svg");
      background-repeat: no-repeat;
      color: $red;
      content: "Something went wrong";
      top: $default_spacing / 2;
      position: absolute;
      font-weight: bold;
      line-height: 1.8em;
      padding-left: 1.8em;
      margin-left: -2px; // To account for the icon's viewbox padding.
    }

    .Toastify__progress-bar {
      background-color: $red;
    }
  }
}
