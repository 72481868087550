@import "_vars";

.btn {
  @include base_shadow;
  background-color: $button_dafault;
  color: #fff;
  border-radius: $radius;
  padding: $default_spacing / 2 $default_spacing;
  border: 0;
  cursor: pointer;
  font-size: 0.85rem;
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  transition: 50ms ease-in-out;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  &:disabled {
    background-color: lighten($button_dafault, 40%);
    cursor: not-allowed;
    box-shadow: none;
  }

  span {
    margin-top: 2px;
  }

  &.block {
    width: 100%;
  }
}

.btn-icon {
  margin-right: 5px;
}
