// Colors
$text_default: #454545;
$text_muted: #939393;
$button_dafault: #1c1c1c;
$green: #08a985;
$red: #eb3a45;

// Visual styles
$radius: 6px;
$default_spacing: 16px;

@mixin base_shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

// Breakpoints (≥)
// Source: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Z indexes
$z_modal_overlay: 600;
$z_modal_content: 640;
